import { autoinject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { SingletonService } from "singleton";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { Router } from "aurelia-router";
import BankaService from "services/banka.service";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { json } from "aurelia-fetch-client";
import { noView, processContent } from "aurelia-framework";
import { State } from "state";
import { connectTo } from "aurelia-store";
import { EventAggregator } from "aurelia-event-aggregator";

@noView()
@connectTo()
@processContent(false)
@autoinject()
export class getAccountLanding {
  private me: any;
  private params: any;
  private state: State;

  constructor(
    private bank: YapilyBankDetailsModel,
    private yapilyHttpClient: YapilyHttpClient,
    private router: Router,
    private bankaService: BankaService,
    private preAuthorizationFlow: Boolean = false,
    private userHttpClient: UsersHttpClients,
    private singleton: SingletonService,
    private authService: AuthService,
    private ea: EventAggregator
  ) {}
  activate(params) {
    this.params = params;
    this.bind();
  }

  async bind() {
    this.bank = this.state.selectedBankToAdd;
    await this.checkPreAuthorizationFlow();
    if (this.preAuthorizationFlow == true) {
      this.putPreAuthAuthorization(this.params);
    }
    if (this.params.error) {
      await this.failure();
      return;
    }

    await this.storeConsent(this.params);
  }

  async failure() {
    await this.endFirstTimeFlow();
    this.router.navigateToRoute("agreement_failure");
  }

  async putPreAuthAuthorization(params: any) {
    let httpRequest = this.yapilyHttpClient.fetch(
      "/yapily/account-auth-requests",
      {
        method: "PATCH",
        body: json({
          institutionId: params.institution,
        }),
      }
    );
  }

  async checkPreAuthorizationFlow() {
    try {
      for (let i = 0; i < this.bank.features.length; i++) {
        if (this.bank.features[i] == "INITIATE_PRE_AUTHORISATION") {
          this.preAuthorizationFlow = true;
          return this.preAuthorizationFlow;
        } else {
          this.preAuthorizationFlow = false;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async storeConsent(params: any) {
    let links = {
      consent: params.consent,
      application_user_id: params["application-user-id"],
      user_uuid: params["user-uuid"],
      institution: params.institution,
    };
    this.ea.publish("banks.add.one.backend", links);
    this.endFirstTimeFlow();
    this.router.navigateToRoute("agreement_success");
  }

  async endFirstTimeFlow() {
    let httpRequest = await this.userHttpClient.fetch("/profile", {
      method: "PATCH",
      body: JSON.stringify({
        isFirstTime: false,
      }),
    });
  }
}
