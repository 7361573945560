import { json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { SingletonService } from 'singleton';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { YapilyHttpClient } from 'http_clients/YapilyHttpClient';
import { YapilyBankDetailsModel } from 'components/models/YapilyModels/YapilyBankDetailsModel';
import { I18N } from 'aurelia-i18n';

@autoinject
export class AgreementsUsers {
  private bank: YapilyBankDetailsModel;
  private isOurConditionsAccepted: boolean;
  private isYapilyConditionsAccepted: boolean;
  private isError: boolean = false;
  private shaking: boolean = false;
  private isLoading: boolean = false;
  private id: string;
  private password: string;
  private response
  private preAuthorizationFlow: boolean = false;
  private preEmbededAuthorizationFlow: boolean = false;
  private errorMessage: string;
  private inputPsw: HTMLInputElement;


  constructor(private router: Router,
    private singleton: SingletonService,
    private yapilyHttpClient: YapilyHttpClient,
    private i18n: I18N
  ) {

  }

  attached() {
    this.bank = this.singleton.getAddedBank();
  }
  
  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }
  async makeAgreement() {
    this.isLoading = true;
    this.isError = false;

    let method = 'POST';
    let body = json({
      institutionId: this.bank.id,
      id: this.id,
      password: this.password
    })
    let params = {
      method: method,
      body: body
    }

      this.response = await this.yapilyHttpClient.fetch('/yapily/embedded-account-auth-requests', params);

    if (this.response.status == 422) {
      this.isError = true;
      this.errorMessage = this.i18n.tr('home.error.agreement_users_error_consent');

      setTimeout(() => {
        this.router.navigateToRoute('account_overview')
      }, 7500);
      return
    }
    if (this.response.status == 403) {
      this.isError = true;
      this.errorMessage = this.i18n.tr('home.error.agreement_users_not_recognize');
    }

    if (this.response.status == 400) {
      this.isError = true;
      this.errorMessage = this.i18n.tr('home.error.reauthorize_users_error_error');
      this.isLoading = false;
    }
    if (this.response.status == 404) {
      this.isError = true;
      this.errorMessage = this.i18n.tr('home.error.agreement_users_incorrect_user');
      this.isLoading = false;
    }


    let agreement: any = await this.response.json();
    this.goToYapily(agreement.data.authorisationUrl)
  }
  async goToYapily(url: string) {
    window.location.replace(url);
  }

  goToHome() {
    this.router.navigateToRoute('home')
  }

  showPassword() {
    this.inputPsw.type === "password" ? this.inputPsw.type = "text" : this.inputPsw.type = "password"
  }
}
